import './App.css';
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PaymentForm from "./OrderDetailComponent";
import Review from "./PersonComponent";
import {createTheme} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Copyright from "./Copyright";
import Grid from "@mui/material/Grid";
import LoadingButton from '@mui/lab/LoadingButton';
import {lulin, mladost} from "./DepartamentsInfo";
import ToolbarComponent from "./ToolbarComponent";
import moment from "moment";
import MessageDialog from "./MessageDialog";

function MainForm(props) {

  /*
    1.1.
  */
  const [service, setService] = React.useState('');

  /*
    1.2.
  */
  const [serviceType, setServiceType] = React.useState('');
  const [date, setDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [hourSelected, setHourSelected] = React.useState('');

  /*
    2.
   */
  const [phone, setPhone] = useState(null);
  const [plate, setPlate] = useState(null);

  const [name, setName] = useState(null);
  const [clientId, setClientId] = useState(0);
  const [email, setEmail] = useState(null);

  const [brand, setBrand] = useState(null);
  const [brandName, setBrandName] = useState(null);

  const [isHotelVisible, setHotelVisible] = useState(false);
  const [hotelPersonalChoice, setHotelPersonalChoice] = useState(null);
  const [hotelToCar, hasHotelToCar] = useState(false);
  const [hotelShelf, setHotelShelf] = useState(null);

  const [hotelOtherServiceOpen, setHotelOtherServiceOpen] = useState(false);

  const [message, setMessage] = React.useState(null);
  const [reservationFinished, setReservationFinished] = React.useState(null);

  const clearAll = () => {
    setService('')
    setServiceType('')
    setDate(null)
    setFormattedDate(null)
    setHourSelected('')
    setPhone(null)
    setPlate(null)
    setName(null)
    setClientId(null)
    setEmail(null)
    setBrand(null)
    setBrandName(null)
    setNotificationLink(null)

    setHotelPersonalChoice(null)

    setHotelVisible(false)
    setHotelOtherServiceOpen(false)
  }

  /*
  Thank you page
   */
  const [notificationLink, setNotificationLink] = useState(null);

  const steps = ['Сервиз', 'Услуга', 'Клиент'];




  function checkHourBusy() {
    let serviceId=service
    //console.log('checking for ', hourSelected)
    let hour = hourSelected;

    if (!reservationFinished && serviceId && serviceType && date && hour) {
      const zeroPad = (num, places) => String(num).padStart(places, '0')

      const dateFormatted = date.year().toString() + "-" + zeroPad((date.month() + 1).toString(), 2) + "-" + zeroPad(date.date().toString(), 2)

      fetch(`${process.env.REACT_APP_URL}/api/schedule?` + new URLSearchParams({
        id: serviceType,
        depid: serviceType,
        data: dateFormatted,
        serviceid: serviceId
      }), {
        method: "GET",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        }
      })
          .then(response => response.json())
          .then(
              (result) => {
                if (result && result.data) {
                  if (props.editMode && props.hourSelectedInitial) {
                    result.data.push({
                      fhour: props.hourSelectedInitial,
                      limit: 1,
                      used: '0',
                      isonline: '0',
                      tmp: '30-Jun-2022 2:38'
                    })
                  }

                  if (props.editMode) {
                    result.data.sort((a, b) => parseFloat(a.fhour) - parseFloat(b.fhour));
                  }

                  let hours = (result.data) ?? [];
                  if (hours.find((h) => (h.fhour === hour) && (Number(h.used) === Number(h.limit))) ||
                      !hours.find((h) => (h.fhour === hour))) {
                    setMessage('За съжаление някой току що зае часът, който искахте да запазите!')
                    setHourSelected('')
                  }
                }
              },
              (error) => {

              }
          );
    }
  }

  const TEN_SEC_MS = 10000;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!reservationFinished) {
        checkHourBusy();
      }
    }, TEN_SEC_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [reservationFinished, hourSelected])


  function getStepContent(step) {
    switch (step) {
      case 0:
      case 1:
        return <PaymentForm
            isHotel={isHotelVisible}
            setHotel={setHotelVisible}
            hotelOtherServiceOpen={hotelOtherServiceOpen}
            setHotelOtherServiceOpen={setHotelOtherServiceOpen}

            service={service}
            setService={setService}
            serviceType={serviceType}
            setServiceType={setServiceType}
            date={date}
            setDate={setDate}
            setFormattedDate={setFormattedDate}
            hourSelected={hourSelected}
            setHourSelected={setHourSelected}
            handleNext={handleNext}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
        />;
      case 2:
        return <Review
            service={service}
            serviceType={serviceType}
            date={date}
            setActiveStep={setActiveStep}
            setHotelOtherServiceOpen={setHotelOtherServiceOpen}

            phone={phone}
            setPhone={setPhone}
            plate={plate}
            setPlate={setPlate}
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            brand={brand}
            setBrand={setBrand}
            brandName={brandName}
            setBrandName={setBrandName}
            clientId={clientId}
            setClientId={setClientId}
            isHotel={isHotelVisible}
            setHotel={setHotelVisible}

            hotelPersonalChoice={hotelPersonalChoice}
            setHotelPersonalChoice={setHotelPersonalChoice}

            hasHotelToCar={hasHotelToCar}
            setHotelShelf={setHotelShelf}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  const theme = createTheme({
    palette: {
      primary: {main: '#30cfc4'},
    },
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    //window.history.pushState(null, null, window.location.pathname);

    /*if (activeStep === 1) {
      setServiceType("")
      setDate(null)
      setHourSelected("")
    }*/

    if (activeStep === steps.length - 1) {


      //send reservation

      setReservationFinished(true)
      fetch(`${process.env.REACT_APP_URL}/api/reservation`, {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...hotelPersonalChoice === "1" ? {force_add_hotel: "1"} : {force_add_hotel: "0"},
          serviceID: service,
          depid: serviceType,
          data: formattedDate,
          hour: hourSelected,
          reg_plate: plate.licence_plate,
          modelid: plate.make_id || brand,
          modelname: plate.make || brandName,
          cliid: clientId,
          phone: phone ? phone.replace(/ /g,'') : null,
          email: email,
          fullname: name,
          serviceid: service,
          ishotel: hotelToCar ? 1 : null,
          shelf: hotelShelf
        })
      })
          .then(response => response.json())
          .then(
              (result) => {
                if (result && result.link) {
                  setNotificationLink(result.link)
                }
              },
              (error) => {

              }
          );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getServiceType = (serviceType, service) => {
    const servicesToCheck = service === 1 ? lulin : mladost;


    for (const serviceChecked of servicesToCheck) {
      if (serviceChecked.id === serviceType) {
        return serviceChecked.name;
      }
    }
    return "";
  }

  return (
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
              position="absolute"
              color="default"
              elevation={0}
              style={{borderBottom: 'none', marginTop: 20}}
              sx={{
                position: 'relative',
                borderBottom: (t) => `1px solid ${t.palette.divider}`,
              }}
          >
            <ToolbarComponent
                clearAll={clearAll} setActiveStep={setActiveStep}/>
          </AppBar>
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper style={{backgroundColor: '#30cfc4'}} elevation={6} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <Typography component="h1" variant="h4" align="center" style={{color: 'white'}}>
                { activeStep === steps.length ?
                    'Успешна резервация' : 'Онлайн резервация'
                }
              </Typography>
            </Paper>

            <Paper elevation={6}>
              <Stepper activeStep={activeStep} sx={{ pt: 1, pb: 1 }}>
                {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
              </Stepper>
            </Paper>
              <Paper elevation={6} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
              <React.Fragment>
                {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography variant="h5" gutterBottom>
                        Успешна резервация! Благодарим Ви!
                      </Typography>
                      <Paper variant="outlined" sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}>
                        { (serviceType === 1 && isHotelVisible) &&
                          <Typography>
                            Ще вземем вашия хотел за гуми!
                          </Typography>
                        }
                        { (serviceType === 7) &&
                          <Typography>
                            Важно! При закъснение от над 3 минути, ще трябва да Ви презапишем за следващия свободен час, поради техническа невъзможност да приемем следващия клиент. Моля проверете предварително за платен пътен данък.
                          </Typography>
                        }
                        <Typography>
                          <b>Услуга:</b> {getServiceType(serviceType, service)}
                        </Typography>
                        <Typography>
                          <b>Локация:</b> {service === 1 ? "6+ Люлин" : "6+ Младост"}
                        </Typography>
                        <Typography>
                          <b>Дата:</b> { date != null && date.toDate() != null ?
                            date.toDate().toLocaleDateString("bg-BG", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                            : null}
                        </Typography>
                        <Typography>
                          <b>Час:</b> {hourSelected}
                        </Typography>
                      </Paper>
                      <br/>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <LoadingButton loading={!notificationLink} startIcon={<CalendarMonthIcon />}
                                         variant={'contained'} target="_blank" href={notificationLink}>
                            Добави в календар
                          </LoadingButton>

                        </Grid>

                        {/*<Grid item xs={12} sm={6}>
                          <Button color="secondary" startIcon={<HomeOutlinedIcon />} variant={'contained'}
                                  onClick={() => {
                                    clearAll();
                                    setActiveStep(0)
                                  }}>
                            Към начална страница
                          </Button>
                        </Grid>*/}
                      </Grid>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                      {getStepContent(activeStep)}
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {activeStep !== 0 && activeStep !== 1 && (
                            <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                              Назад
                            </Button>
                        )}

                        <Button
                            variant="contained"
                            onClick={handleNext}
                            disabled={
                              activeStep === 0 ? service === '' || hotelOtherServiceOpen :
                              activeStep === 1 ? serviceType === '' || date === null || (isHotelVisible && moment().add(1,'days').isSameOrAfter(moment(date), 'day')) || hourSelected === '' :
                              activeStep === 2 ? (serviceType === 1 ? (!hotelPersonalChoice)  : false)
                                              || (phone === '' || phone === null)
                                              || (plate === '' || plate === null)
                                              || (name === ''  || name === null)
                                              || (brand === '' || brand === null) :
                                  false
                            }
                            sx={{ mt: 3, ml: 1 }}
                        >
                          {activeStep === steps.length - 1 ? 'Готово' : 'Напред'}
                        </Button>
                      </Box>
                    </React.Fragment>
                )}
              </React.Fragment>
            </Paper>
            <Copyright />
          </Container>


          <MessageDialog
              openAndText={message}
              handleClose={() => {
                setActiveStep(1)
                setMessage(null)
              }}
              dangerMode
              positiveText={'Смени го'}
              positiveAction={() => {
                setActiveStep(1)
                setMessage(null)
              }}/>
        </ThemeProvider>
      </React.StrictMode>

  );
}

export default MainForm;
