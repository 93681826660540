import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import RadioGroup from "@mui/material/RadioGroup";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import moment from "moment";
import {useParams} from "react-router";

function ConfirmationDialog(props) {
    let params = useParams();

    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        props.setCancelDialogOpen(false);
    };

    const handleOk = () => {
        fetch(`${process.env.REACT_APP_URL}/api/reservation/${params.orderId}`, {
            method: "DELETE",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    props.setCancelDialogOpen(false);
                    props.setEditState(props.CANCELED)
                },
                (error) => {

                }
            );
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>Отмяна на поръчка</DialogTitle>
            <DialogContent dividers>
                <Typography>Сигурни ли сте, че желаете да отмените поръчката?</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="error">
                    Не
                </Button>
                <Button onClick={handleOk}>Отмени поръчка</Button>
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmationDialog;