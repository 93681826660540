import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import logo from "./logo.png";
import phone from "./phone-call.png";
import WifiCalling3OutlinedIcon from "@mui/icons-material/WifiCalling3Outlined";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";

// clearAll, setActiveStep
function ToolbarComponent(props) {
    return (
        <Toolbar style={{backgroundColor: '#f6f5f5'}} onClick={() => {
            props.clearAll();
            props.setActiveStep(0)
        }}>
            <Paper style={{width: '100%'}} elevation={6}>
            <Grid container justifyContent="space-between"   alignItems="center"
                  style={{paddingLeft: 10, paddingRight: 30}}
            >
                <Grid item xs={2} sm={4}>
                    <img style={{width: 80, height: 80, cursor: 'pointer'}} src={logo} alt={"6 Плюс"}/>
                </Grid>

                <Grid item xs={10} sm={8}>
                    <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>

                        <Grid item>
                            <img src={phone} style={{width: 42, height: 42}}/>
                        </Grid>


                        <Grid item>
                            <Grid coontainer justifyContent="flex-end">
                                <Typography>За въпроси и поръчки</Typography>
                            <a href="tel:+359882606606" style={{color: '#ffd200', textDecoration: 'none', textShadow: '8px 2px #fff'}}>
                                <Typography variant="h5" noWrap>
                                    0882 606 606
                                </Typography>
                            </a>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>

            </Grid>
            </Paper>


        </Toolbar>
    );
}
export default ToolbarComponent;