import React, { Component } from "react";
import MaskedInput from "react-text-mask";

export class TextInput extends Component {

    render() {
        const { inputRef, ...other } = this.props;

        return (
            <MaskedInput
                {...other}
                ref={ref => {
                    //inputRef(ref ? ref.inputElement : null);
                }}
                mask={[
                    '0',
                    /[0-9]/,
                    /[0-9]/,
                    ' ',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    ' ',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/
                ]}
                placeholder="089 123 4567"
            />
        );
    }
}
