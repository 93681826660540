import logo from './logo.svg';
import './App.css';
//import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import MainForm from "./MainForm";
import EditForm from "./EditForm";

function App() {
  return (
      <div style={{backgroundColor: '#f6f5f5'}}>
        <Routes>
          <Route path="/" element={<MainForm />} />
          <Route path="/:orderId" element={<EditForm />} />
        </Routes>
      </div>
  );
}

export default App;
