import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import {useEffect, useState} from "react";
import {TextInput} from "./textinput";
import {PlateTextinput} from "./plateTextinput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import moment from "moment";
import HasHotelDialog from "./HasHotelDialog";
import HasHotelOtherServiceDialog from "./HasHotelOtherServiceDialog";
import HotelIcon from '@mui/icons-material/Hotel';
import {FormLabel, Radio, Tooltip} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";

export default function Review(props) {
    const cyrillicToLatinMap = {
        "А": "A", "Б": "B", "В":"B", "Е": "E", "Ё": "Yo",
        "З": "Z", "И": "I", "Й": "Y", "К": "K", "Л": "L", "М": "M", "Н": "H", "О": "O",
        "П": "P", "Р": "P", "С": "C", "Т": "T", "У": "Y", "Ф": "F", "Х": "X", "Ц": "C",
        "Ч": "Ch", "Ш": "Sh", "Щ": "Sch", "Ы": "Y", "Ь": "", "Э": "E", "Ю": "Yu", "Я": "Ya",
        "S": 'C', 'W': 'B',
    };

    //focuses
    const [isPhoneFocused, setPhoneFocused] = React.useState(true);
    const [isNameFocused, setNameFocused] = React.useState(false);
    const [isEmailFocused, setEmailFocused] = React.useState(false);
    const [isPlateFocused, setPlateFocused] = React.useState(false);
    const [isBrandFocused, setBrandFocused] = React.useState(false);


    const [brands, setBrands] = useState([]);
    const [clientCars, setClientCars] = useState([]);

    const [hotelDialogOpen, setHotelDialogOpen] = useState(false);
    const [hotelOtherServiceDialogOpen, setHotelOtherServiceDialogOpen] = useState(false);
    const [hotelService, setHotelService] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/getBrands`, {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result.data) {
                        setBrands(JSON.parse(result.data));
                    }
                },
                (error) => {

                }
            );
    }, []);

    useEffect(() => {
        if (props.phone) {
            const trimmedValue = props.phone.replace(/[^0-9]/g,"");
            if (trimmedValue.length === 10) {
                searchClient(trimmedValue);
            }
        }

    }, [])

    const propToHighlight = (prop)  => {
        let highlightPhone = false;
        let highlightName = false;
        let highlightEmail = false;
        let highlightPlate = false;
        let highlightBrand = false;

        switch (prop) {
            case "PHONE":
                highlightPhone = true;
                break;
            case "NAME":
                highlightName = true;
                break;
            case "EMAIL":
                highlightEmail = true;
                break;
            case "PLATE":
                highlightPlate = true;
                break;
            case "BRAND":
                highlightBrand = true;
                break;
        }
        setPhoneFocused(highlightPhone);
        setNameFocused(highlightName);
        setEmailFocused(highlightEmail);
        setPlateFocused(highlightPlate);
        setBrandFocused(highlightBrand);
    }

    const handleChange = (event) => {
        props.setBrand(event.target.value);

        let brandName = '';
        for (let i=0; i<brands.length; i++) {
            if (brands[i].id === event.target.value) {
                brandName = brands[i].n;
            }
        }
        props.setBrandName(brandName);
    };

    const searchClient = (phoneTrimmed) => {
        fetch(`${process.env.REACT_APP_URL}/api/getClientByPhone`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: 1,
                phone: phoneTrimmed,
                workshop: props.service
            })
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result.data) {
                        if (result.data.cliid !== 0) {
                            props.setClientId(result.data.cliid)
                            const nameArray = result.data.cliname.split(" ");
                            if (nameArray.length >= 2) {
                                const name = nameArray[0] + " " + nameArray[1];
                                props.setName(name)
                                if (!result.data.email) {
                                    propToHighlight("EMAIL");
                                }
                            } else {
                                propToHighlight("NAME");
                            }

                            if (result.data.email && result.data.email.includes('@')) {
                                props.setEmail(result.data.email)
                                if (nameArray.length < 2) {
                                    propToHighlight("NAME");
                                }
                            } else {
                                propToHighlight("EMAIL");
                            }

                            if (nameArray.length >= 2 && result.data.email) { // if both
                                propToHighlight("PLATE");
                            }

                            setClientCars(result.data.cars);
                        } else {
                            props.setName("");
                            propToHighlight("NAME");
                        }
                    }
                },
                (error) => {

                }
            );
    };

    const removeCyrillicAndTrim = (newValue) => {
        if (newValue) {
            let modifiedValue = newValue.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '').toUpperCase().trim().split('').map(char => cyrillicToLatinMap[char] || char).join('');
            return modifiedValue.replace(/[^a-zA-Zа-яА-ЯёЁ0-9]/g, '').toUpperCase().trim();

        }
        return newValue;
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Данни
            </Typography>

            <HasHotelDialog
                setActiveStep={props.setActiveStep}
                open={hotelDialogOpen}
                setCancelDialogOpen={setHotelDialogOpen}/>

            <HasHotelOtherServiceDialog
                setPlate={props.setPlate}
                setBrand={props.setBrand}

                setActiveStep={props.setActiveStep}
                open={hotelOtherServiceDialogOpen}
                hotelService={hotelService}
                setHotelOtherServiceOpen={props.setHotelOtherServiceOpen}
                setCancelDialogOpen={setHotelOtherServiceDialogOpen}/>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <FormControl fullWidth variant="standard" focused={isPhoneFocused}>
                        <InputLabel shrink={props.phone !== null && props.phone !== "" } htmlFor="phone-num-mask">Телефонен номер</InputLabel>
                        <Input value={props.phone} onChange={(val) => {
                            const trimmedValue = val.target.value.replace(/[^0-9]/g,"");
                            if (trimmedValue.length === 10) {
                                searchClient(trimmedValue);
                            } else {
                                if (props.name) {
                                    props.setName("");
                                }
                                if (props.email) {
                                    props.setEmail("");
                                }
                                setClientCars([]);
                            }
                            props.setPhone(val.target.value)
                            propToHighlight("PHONE")
                        }}
                               id={"phone-num-mask"}
                               disabled={props.editMode}
                               inputComponent={TextInput} />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            required
                            id="name"
                            name="name"
                            label="Име"
                            focused={isNameFocused}
                            fullWidth
                            disabled={props.editMode}
                            onChange={(event) => {
                                props.setName(event.target.value)
                            }}
                            value={props.name}
                            InputLabelProps={{ shrink: props.name !== null && props.name !== "" }}
                            autoComplete="name"
                            variant="standard"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth variant="standard">
                        <TextField
                            id="email"
                            name="email"
                            label="Имейл за потвърждение"
                            focused={isEmailFocused}
                            disabled={props.editMode}
                            fullWidth
                            onChange={(event) => {
                                props.setEmail(event.target.value)
                            }}
                            InputLabelProps={{ shrink: props.email !== null && props.email !== "" }}
                            value={props.email}
                            autoComplete="email"
                            variant="standard"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item xs={props.isHotel ? 10 : 12}>
                    <FormControl fullWidth variant="standard">
                        <Autocomplete
                            value={props.plate}
                            disabled={props.editMode}
                            freeSolo
                            onChange={(event, newValue) => {
                                props.setPlate(newValue);
                                props.setBrand(newValue.make_id);
                                props.setHotel(newValue.ishotel === 1);
                                if (newValue.ishotel === 1) {
                                    let currentReservation = moment(props.date);
                                    let tomorrow = moment().add(1,'days');

                                    if (tomorrow.isSameOrAfter(currentReservation, 'day') && props.serviceType === 1) {
                                        setHotelDialogOpen(true);
                                    }
                                    if (props.hasHotelToCar && props.setHotelShelf) {
                                        props.hasHotelToCar(true)
                                        props.setHotelShelf(newValue.shelf)
                                    }
                                }

                                if (newValue.isHotelServiceId > 0 && props.service !== newValue.isHotelServiceId && props.serviceType === 1) {
                                    props.setHotelOtherServiceOpen(true)

                                    setHotelService(newValue.isHotelServiceId)

                                    setHotelOtherServiceDialogOpen(true)
                                }

                            }}
                            onInputChange={(e, newValue) => {
                                props.setPlate( {licence_plate: removeCyrillicAndTrim(newValue)} )
                            }
                            }
                            id={"plate-mask"}
                            getOptionLabel={(option) => {
                                return option.licence_plate || props.plate
                            }}
                            renderOption={(props, option) => (
                                <Box id={option.licence_plate} {...props}>
                                    <div style={{"display": "grid"}}>
                                        <Typography>{option.licence_plate}</Typography>
                                        <FormHelperText>{option.make}</FormHelperText>
                                    </div>
                                </Box>
                            )}
                          renderInput={(params) => <TextField {...params}
                                                              focused={isPlateFocused}
                                                              label="Регистрационен номер"
                                                               variant="standard" inputComponent={PlateTextinput}/>}
                          options={clientCars}
                        />
                    </FormControl>
                </Grid>
                {
                    (props.isHotel || props.hotelPersonalChoice === "1") &&
                    <Grid item xs={1}>
                        <Tooltip title={'Ще се погрижим за вашия хотел за гуми!'}>
                            <HotelIcon/>
                        </Tooltip>
                    </Grid>
                }
                    </Grid>
                </Grid>

                {props.serviceType === 1 ? <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="has-hotel-label">Имате ли гуми на съхранение в 6+?</FormLabel>
                        <RadioGroup
                            row
                            value={props.hotelPersonalChoice}
                            onChange={(event) => {
                                props.setHotelPersonalChoice(event.target.value);
                            }}
                            aria-labelledby="has-hotel-label-label"
                            name="has-hotel-label-buttons-group"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Да" />
                            <FormControlLabel value="0" control={<Radio />} label="Не" />
                        </RadioGroup>
                    </FormControl>
                </Grid> : null}

                <Grid item xs={12}>
                    <FormControl fullWidth variant="standard"
                    >
                        <InputLabel shrink={props.brand !== null && props.brand !== ""} id="demo-simple-select-label">Марка</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            focused={isBrandFocused}
                            disabled={props.editMode}
                            value={props.brand}
                            label="Марка"
                            onChange={handleChange}
                        >
                            {
                                brands && brands.map((brand) => {
                                    return <MenuItem value={brand.id}>{brand.n}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}