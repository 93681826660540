import Typography from "@mui/material/Typography";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {getServiceById} from "./DepartamentsInfo";

function HasHotelOtherServiceDialog(props) {

    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };
    const handleOk = () => {
        props.setCancelDialogOpen(false);
        props.setActiveStep(0);
        props.setPlate(null);
        props.setBrand(null);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>Хотел</DialogTitle>
            <DialogContent>
                <Typography>Имате гуми на хотел в {getServiceById(props.hotelService).name}. Необходимо е да запазите час в {getServiceById(props.hotelService).name}!
                    За въпроси: 0882 606 606.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>Добре</Button>
            </DialogActions>
        </Dialog>
    );
}
export default HasHotelOtherServiceDialog;