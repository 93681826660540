export const lulin = [{id: 1, name: "Гуми"}, {id: 4, name: "Реглаж"}, {id: 7, name: "ГТП"}];

export const mladost = [{id: 1, name: "Гуми"}, {id: 4, name: "Реглаж"}];

export const getServiceById = (id) => {
    switch (id) {
        case 1:
            return {
                id: 1,
                name: "Люлин"
            }
        case 2:
            return {
                id: 2,
                name: "Младост"
            }
        default:
            return {
                id: 0,
                name: ""
            }
    }
}