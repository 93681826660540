import Typography from "@mui/material/Typography";
import React from "react";
import Paper from "@mui/material/Paper";
import {lulin, mladost} from "./DepartamentsInfo";


const getServiceType = (serviceType, service) => {
    const servicesToCheck = service === 1 ? lulin : mladost;

    for (const serviceChecked of servicesToCheck) {
        if (serviceChecked.id === serviceType) {
            return serviceChecked.name;
        }
    }
    return "";
}

//serviceType, service, date, hourSelected
function SummaryCard(props) {
    return (
        <Paper variant="outlined" sx={{ my: { xs: 1, md: 2 }, p: { xs: 1, md: 2 } }}>
            <Typography>
                <b>Услуга:</b> {getServiceType(props.serviceType, props.service)}
            </Typography>
            <Typography>
                <b>Локация:</b> {props.service === 1 ? "6+ Люлин" : "6+ Младост"}
            </Typography>
            <Typography>
                <b>Дата:</b> { props.date != null && props.date.toDate() != null ?
                props.date.toDate().toLocaleDateString("bg-BG", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                : null}
            </Typography>
            <Typography>
                <b>Час:</b> {props.hourSelected}
            </Typography>
        </Paper>
    );
}
export default SummaryCard;