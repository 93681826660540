import React, { Component } from "react";
import MaskedInput from "react-text-mask";

export class PlateTextinput extends Component {

    render() {
        const { inputRef, ...other } = this.props;
        return (
            <MaskedInput
                {...other}
                ref={ref => {
                    //inputRef(ref ? ref.inputElement : null);
                }}
                mask={[
                    '/[A-Z]/',
                    '/[A-Z]/',
                    ' ',
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    /[0-9]/,
                    ' ',
                    '/[A-Z]/',
                    '/[A-Z]/',
                    /\d/
                ]}
                placeholder="СВ 1234 АА"
            />
        );
    }
}
