import './App.css';
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PaymentForm from "./OrderDetailComponent";
import {createTheme} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {useNavigate, useParams} from "react-router";
import Copyright from "./Copyright";
import moment from "moment";
import Grid from "@mui/material/Grid";
import ConfirmationDialog from "./ConfirmationDialog";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SummaryCard from "./SummaryCard";
import ToolbarComponent from "./ToolbarComponent";
import LoadingButton from "@mui/lab/LoadingButton";

function EditForm(props) {
  let params = useParams();
  let navigate = useNavigate();

  const EDITING = 'EDITING';
  const DONE = 'DONE';
  const CANCELED = 'CANCELED';

  const STATES = [EDITING, DONE, CANCELED]

  const [editState, setEditState] = React.useState(EDITING);

  const [isHotelVisible, setHotelVisible] = useState(false);

  /*
    1.
  */
  const [service, setService] = React.useState('');

  /*
    2.
  */
  const [serviceType, setServiceType] = React.useState('');
  const [date, setDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [hourSelected, setHourSelected] = React.useState('');
  const [hourSelectedInitial, setHourSelectedInitial] = React.useState('');

  /*
    3.
   */
  const [phone, setPhone] = useState(null);
  const [plate, setPlate] = useState(null);

  const [name, setName] = useState(null);
  const [clientId, setClientId] = useState(0);
  const [email, setEmail] = useState(null);

  const [brand, setBrand] = useState(null);

  /*
  Thank you page
   */
  const [notificationLink, setNotificationLink] = useState(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);


  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/reservation/${params.orderId}`, {
      method: "GET",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      }
    })
        .then(response => response.json())
        .then(
            (result) => {
              setService(result.warehouse_id)
              setServiceType(result.department_id)
              setDate(new moment(result.date))
              setHourSelected(result.hour !== "00:00" ? result.hour : null)
              setHourSelectedInitial(result.hour !== "00:00" ? result.hour : null)
              setPhone(result.client.telephone)

              const nameArray = result.client.name.split(" ");
              if (nameArray.length >= 2) {
                const name = nameArray[0] + " " + nameArray[1];
                setName(name)
              }
              setEmail(result.client.email);
              setPlate(result.car.licence_plate);
              setBrand(result.car.make);

              setHotelVisible(result.is_hotel && result.department_id === 1) //if has a hotel and the service is "Гуми"
              if (result.order_status >= 5) {
                  setEditState(CANCELED)
              }
            },
            (error) => {

            }
        );
  }, []);

  /*
  const onBackButtonEvent = (e) => {
    if (activeStep >= 1) {
      e.preventDefault();
      setActiveStep(activeStep - 1);
    } else {
     console.log(window.history)
    }
  }
*/


  const steps = ['Сервизен обект', 'Услуга', 'Данни за човека'];

  const theme = createTheme({
      palette: {
          primary: {main: '#30cfc4'},
      },
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);

  const handleNext = () => {
      setLoading(true)

      //send reservation

      fetch(`${process.env.REACT_APP_URL}/api/reservation`, {
        method: "PUT",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serviceID: service,
          depid: serviceType,
          data: formattedDate,
          hour: hourSelected,
          reg_plate: plate.licence_plate,
          modelid: plate.make_id,
          modelname: plate.make,
          id: params.orderId,
          cliid: clientId,
          phone: phone ? phone.replace(/ /g,'') : null,
          email: email,
          fullname: name,
          serviceid: service,
        })
      })
          .then(response => response.json())
          .then(
              (result) => {
                  setLoading(false)

                  if (result && result.link) {
                      setNotificationLink(result.link)
                  }
                    setEditState(DONE)
              },
              (error) => {
                  setLoading(false)
              }
          ).catch(() => {
              setLoading(false)
          });
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  return (
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppBar
              position="absolute"
              color="default"
              elevation={0}
              sx={{
                position: 'relative',
                borderBottom: (t) => `1px solid ${t.palette.divider}`,
              }}
          >
              <ToolbarComponent
                  clearAll={() => {}} setActiveStep={() => {}}/>

          </AppBar>
          <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                {editState === EDITING && <React.Fragment>

                    <Typography component="h1" variant="h4" align="center">
                        Редакция на резервация
                  </Typography>

                  <PaymentForm
                      isHotel={isHotelVisible}
                      setHotel={setHotelVisible}

                      service={service}
                      serviceType={serviceType}
                      setServiceType={setServiceType}
                      date={date}
                      editMode={true}
                      setDate={setDate}
                      setFormattedDate={setFormattedDate}
                      hourSelected={hourSelected}
                      hourSelectedInitial={hourSelectedInitial}
                      setHourSelected={setHourSelected}
                      handleNext={() => {}}/>
                </React.Fragment> }

                  <ConfirmationDialog
                    open={cancelDialogOpen}
                    setCancelDialogOpen={setCancelDialogOpen}
                    setEditState={setEditState}
                    CANCELED={CANCELED}
                  />
              <React.Fragment>
                {editState === DONE ? (
                    <React.Fragment>
                      <Typography variant="h5" gutterBottom>
                        Успешна промяна!
                      </Typography>
                      <Typography variant="subtitle1">
                          Успешна резервация! Благодарим Ви!
                      </Typography>
                        <SummaryCard
                            serviceType={serviceType}
                            service={service}
                            date={date}
                            hourSelected={hourSelected}
                        />
                      <br/>
                      {notificationLink &&
                        <Button startIcon={<CalendarMonthIcon />} variant={'contained'} target="_blank" href={notificationLink}>
                          Добави в календар
                        </Button>
                      }
                    </React.Fragment>
                ) :
                    editState === "EDITING" ?
                    (
                    <React.Fragment>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                          <Grid
                              container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                          >
                                  <Button onClick={() => {
                                      setCancelDialogOpen(true)
                                  }} sx={{ mt: 3}} variant="outlined" color="error">
                                      Анулирай
                                  </Button>


                                  <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    disabled={
                                      isLoading ||
                                      !hourSelected ||
                                      (activeStep === 0 ? service === '' :
                                      activeStep === 1 ? serviceType === '' || date === null || hourSelected === '' :
                                      activeStep === 2 ? (phone === '' || phone === null)
                                                      || (plate === '' || plate === null)
                                                      || (name === ''  || name === null)
                                                      || (brand === '' || brand === null) :
                                          false)
                                    }
                                    sx={{ mt: 3, ml: 1 }}
                                >
                                      {isLoading ? 'Моля, изчакайте...' : 'Готово'}
                                </Button>

                          </Grid>
                      </Box>
                    </React.Fragment>
                )
                :
                        //state: CACELLED
                        (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    Успешно отменихте резервацията.
                                </Typography>
                                <Typography variant="subtitle1">
                                    Вашата резервация за сервиз 6+ е отменена. Благодарим Ви, за своевременното уведомление и заповядайте отново!
                                </Typography>
                                <br/>

                            {/*
                                <Button color="secondary" startIcon={<HomeOutlinedIcon />}
                                        onClick={() => {
                                            navigate(`/`);
                                        }}
                                        variant={'contained'}>Към начална страница
                                </Button>*/}
                                <br/>
                            </React.Fragment>
                        )
                }
              </React.Fragment>
            </Paper>
            <Copyright />
          </Container>
        </ThemeProvider>
      </React.StrictMode>

  );
}

export default EditForm;
